<template>
    <div class="activity_sign_log_list">
        <el-card>
            <!-- <el-page-header :content="detail.title" style="margin-bottom: 30px;" @back="goBack" /> -->
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="用户姓名、手机号" />
            </nav>
            <nav class="input" style="width: auto">
                <fuzzySelection
                    size="medium"
                    ref="fuzzySelection"
                    type="4"
                    :value.sync="search.activityId"
                ></fuzzySelection>
            </nav>
            <nav class="input" style="width: auto;">
                <el-input v-model="search.exhibitorCode" size="medium" placeholder="展位号" />
            </nav>
            <nav class="input" style="width: auto;">
                <el-select v-model="search.type" placeholder="签到类型">
                    <el-option label="全部" :value="-1"></el-option>
                    <el-option
                        :label="item.value"
                        :value="item.key"
                        v-for="(item, index) in signType"
                        :key="index"
                    ></el-option>
                </el-select>
            </nav>
            <nav class="input">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="subimt">{{ btn ? "搜索" : "loading.." }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="add()">添加演讲资料</el-button> -->
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID" width="60"> </el-table-column>
                    <el-table-column prop="userId" label="UID" width="60"> </el-table-column>
                    <!-- <el-table-column prop="typeName" label="签到类型"> </el-table-column> -->
                    <el-table-column prop="userAvatar" label="头像">
                        <template #default="{row}">
                            <p-image :src="row.userAvatar" auto> </p-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="姓名">
                        <template #default="{row}">
                            <p>
                                {{ row.userRealName }}
                            </p>
                            <p style="color: green;" v-if="row.userAuth == 1">
                                已实名认证
                            </p>
                            <p style="color: red;" v-else>
                                未实名认证
                            </p>
                            <p v-if="row.userAuth == 1">
                                ({{ row.userAuthType == 0 ? "身份证" : row.userAuthType == 1 ? "港澳通行证" : "护照" }})
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userCompany" label="公司"> </el-table-column>
                    <el-table-column prop="userJob" label="职位"> </el-table-column>
                    <el-table-column prop="activityName" label="活动&票种" width="280">
                        <template #default="{row}">
                            <el-tag type="info" size="mini" effect="dark" v-if="row.activityName">
                                {{ row.activityName }}
                            </el-tag>
                            <p>
                                {{ row.ticketName }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userRemark" label="备注">
                        <template #default="{row}">
                            <Remark
                                v-if="+row.userId"
                                :row="row"
                                url="/admin/User/editRemark"
                                rowKey="remark"
                                idKey="userId"
                                @getlist="getlist"
                            ></Remark>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userOperationRemark" label="运营备注">
                        <template #default="{row}">
                            <Remark
                                v-if="+row.userId"
                                :row="row"
                                url="admin/User/editOperationRemark"
                                rowKey="operationRemark"
                                idKey="userId"
                                @getlist="getlist"
                            ></Remark>
                        </template>
                    </el-table-column>

                    <el-table-column prop="createTime" label="签到信息">
                        <template #default="{row}">
                            <p>{{ row.typeName }}</p>
                            <p>{{ row.enterpriseName }}</p>
                            <p v-if="row.exhibitorCode">（展位：{{ row.exhibitorCode }}）</p>
                            <p>
                                {{ row.createTime }}
                            </p>
                        </template>
                    </el-table-column>
                    <!-- 
                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <el-button
                                type="text"
                                icon="el-icon-edit"
                                size="mini"
                                style="color: blue;margin: 3px;"
                                @click="add(row)"
                            >
                                编辑</el-button
                            >
                            <el-popconfirm @confirm="onClose(row)" title="是否继续操作" style="margin: 5px;">
                                <el-button slot="reference" type="text" icon="el-icon-circle-close" size="mini">{{
                                    row.close == 0 ? "关闭" : "开启"
                                }}</el-button>
                            </el-popconfirm>
                            <el-popconfirm
                                @confirm="hot(row, row.top ? 0 : 1)"
                                title="是否继续操作"
                                style="margin: 5px;"
                            >
                                <el-button slot="reference" type="text" icon="el-icon-share" size="mini" class="a5">{{
                                    row.top ? "不推荐" : "推荐"
                                }}</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column> -->
                </el-table>
            </z-table>
        </el-card>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import fuzzySelection from "/src/components/fuzzySelection";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
                activityId: "",
                exhibitorCode: "",
                type: "",
            },
            detail: {
                title: "加载中...",
            },
            btn: true,
            configDetail: {},

            signType: [],
        };
    },

    activated() {
        // console.log(1);
    },
    components: {
        fuzzySelection,
    },
    created() {
        this.init();
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        reset() {
            this.search = this.$options.data.call(this).search;
            this.getlist();
        },
        onSearch(val) {
            this.search = val;
            this.getlist();
        },
        onAdd() {
            this.$router.push({ name: "activity_speaker_ppt" });
        },
        onEdit(row) {
            this.$router.push({ name: "activity_speaker_ppt", query: { id: row.id } });
        },
        async openEntrance(done) {
            let { data: res } = await this.$http.post("/admin/ActivityPpt/close", {
                id: this.$route.query.id,
                close: this.configDetail.close == 1 ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                await this.getConfigDetail();
                done();
            }
        },
        async hot({ id }, top) {
            await this.$http.post("/admin/ActivityPpt/configTop", {
                id,
                top,
            });
            this.getlist();
        },
        async init() {
            await this.getDetail();
            // this.getConfigDetail();
        },
        setUp() {
            this.$refs.Setting.init();
        },
        add({ activityId: id }) {
            this.$router.push({ name: "activity_speaker_ppt", query: { id } });
        },
        async subimt() {
            this.btn = false;
            await this.$refs.table.refresh();
            this.btn = true;
        },
        async getDetail() {
            let { data: res } = await this.$http.get("/admin/Common/getConfigDictionaries?config=activity");
            this.signType = res.data?.signType;
        },
        getConfigDetail() {
            return new Promise(async (resolve, reject) => {
                try {
                    let {
                        data: { data },
                    } = await this.$http.get("/admin/ActivityPpt/getConfigDetail", {
                        params: { id: this.$route.query.id },
                    });
                    this.configDetail = {
                        ...data,
                        title: data.title || this.detail.title,
                        desc: data.desc || this.detail.desc,
                        banner: data.banner || this.detail.banner,
                    };
                    resolve(this.configDetail);
                } catch (error) {
                    reject(error);
                }
            });
        },
        async onClose({ activityId: id, close }) {
            await this.$http.post("/admin/ActivityPpt/close", { id, close: close ? 0 : 1 });
            this.getlist();
        },
        async queryList(val) {
            // console.log(this.search);
            const params = { ...val, ...this.search };
            if (params.type === "") params.type = -1;
            let { data: res } = await this.$http.get("/admin/Activity/getSignLogList", {
                params,
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                // res.data.list = res.data.list.map((row) => {
                //     return {
                //         ...row,
                //         remark: row.userRemark,
                //         operationRemark: row.userOperationRemark,
                //     };
                // });

                this.$refs.table.complete(res.data);
            }
        },
        // async onDelete(row) {
        //     await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        //         confirmButtonText: "确定",
        //         cancelButtonText: "取消",
        //         type: "warning",
        //     });
        //     let { data: res } = await this.$http.post("/admin/ActivityPpt/del", { id: row.id });
        //     if (res.errorCode == 200) {
        //         this.$message.success(res.message);
        //         this.getlist();
        //     }
        // },

        async viewRecords(done, row) {
            done();
            this.$router.push({
                name: "activity_speaker_ppt_log",
                query: {
                    id: row.id,
                },
            });
            // let { data: res } = await this.$http.post("/admin/ActivityPpt/exportExcel", {
            //     id: row.id,
            // });

            // if (res.errorCode == 200) {
            //     // this.$message.success(res.message);
            //     location.href = res.data.url;
            // }
        },
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
